// AboutMore.js
import React, { useState ,useEffect} from 'react';
import { aboutData } from '../data/data'; // Update the path accordingly
import { Link } from 'react-router-dom';

const AboutMore = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
      // Simulate asynchronous data fetching
      setTimeout(() => {
        setData(aboutData);
      }, 1000); // Adjust the time or use a real async function
    }, []);
  
    if (!data) {
      // Display a loading state while fetching data
      return <div className="progress-bar" />;
      
    }

    
  
    const {
      about__id,
      about__title,
      about__subtitle,
      about__img,
      aboutUsContent,
    } = data;

  return (
    <section className="about-more section bd-container" id={about__id}>
      <div className="about__container bd-grid">
        <div className="about__data">
          <span className="section-subtitle about__initial">{about__id}</span>
          <h2 className="section-title about__initial">{about__title}</h2>
          <p className="about__description">{about__subtitle}</p>
        </div>
        <img src={about__img} alt={about__img} className="about__img" />
      </div>

      <div className="about-more__container bd-container bd-grid">
  {/* Render additional content for "About More" */}
            {
                Object.values(aboutUsContent).map((section, index) => (
                <div key={index} className="about-more__section">
                    <h2>{section.title}</h2>
                    {Array.isArray(section.content)
                    ? section.content.map((paragraph, i) => <p key={i}>{paragraph}</p>)
                    : null}
                </div>
                ))}
            </div>
    </section>
  );
};

export default AboutMore;
