import {
  cloudComputingFlatSvg,
  customerServiceAgentFlatSvg,
  dataArrangingIsometricSng,
  dataCenterMonochromaticSvg,
  callCenterFlat51145Svg,
  bugFixingMonochromaticSvg,
  Group,
  Hero,
  chatFlatSvg,
  administratorFlatSvg,
  dataArrangingIsometricPng,
  us,
} from "../assets/img";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "services",
    title: "Services",
  },
  // {
  //   id: "projects",
  //   title: "Projects",
  // },
  {
    id: "about",
    title: "About us",
  },
  // {
  //   id: "contact",
  //   title: "Contact us",
  // },
];

// export const services__Data = [
//     {
//       id: 1,
//       icon: bugFixingMonochromaticSvg,
//       title: "Bug Fixing Services",
//       content:
//         "Our team specializes in identifying and resolving software bugs and issues. We're here to help you quickly resolve any technical glitches.",
//     },
//     {
//       id: 2,
//       icon: callCenterFlat51145Svg,
//       title: "Technical Support Services",
//       content:
//         "We offer comprehensive technical support services, including troubleshooting, software installation, system updates, and network setup. Our experts are here to assist you.",
//     },
//     {
//       id: 3,
//       icon: cloudComputingFlat6029cSvg,
//       title: "Cloud Computing Solutions",
//       content:
//         "Explore our cloud computing solutions that empower your business with scalability, reliability, and cost-effective cloud services.",
//     },
//     {
//       id: 4,
//       icon: dataCenterMonochromaticSvg,
//       title: "Data Center Management",
//       content:
//         "Discover our data center management services, ensuring the security, efficiency, and performance of your data infrastructure.",
//     },
//     {
//       id: 5,
//       icon: customerServiceAgentFlatSvg,
//       title: "Customer Service Excellence",
//       content:
//         "Experience customer service excellence with our dedicated team. We're committed to providing the best support and satisfaction to our customers.",
//     },
//   ];

// export const services__Data = [
//   {
//     id: 1,
//     icon: bugFixingMonochromaticSvg,
//     title: "Bug Fixing Services",
//     content:
//       "Our team specializes in identifying and resolving software bugs and issues. We're here to help you quickly resolve any technical glitches.",
//   },
//   {
//     id: 2,
//     icon: callCenterFlat51145Svg,
//     title: "Technical Support Services",
//     content:
//       "We offer comprehensive technical support services, including troubleshooting, software installation, system updates, and network setup. Our experts are here to assist you.",
//   },
//   {
//     id: 6,
//     icon: /* Custom icon for App Development */ null,
//     title: "App Development",
//     content:
//       "Experience creative app development at its best. We specialize in crafting stunning mobile applications for iOS and Android platforms.",
//   },
//   {
//     id: 7,
//     icon: /* Custom icon for Custom Development */ null,
//     title: "Custom Development",
//     content:
//       "Need a tailored software solution? Our custom development services are designed to meet your unique business requirements.",
//   },
//   {
//     id: 3,
//     icon: cloudComputingFlat6029cSvg,
//     title: "Cloud Computing Solutions",
//     content:
//       "Explore our cloud computing solutions that empower your business with scalability, reliability, and cost-effective cloud services.",
//   },
//   {
//     id: 4,
//     icon: dataCenterMonochromaticSvg,
//     title: "Data Center Management",
//     content:
//       "Discover our data center management services, ensuring the security, efficiency, and performance of your data infrastructure.",
//   },
//   {
//     id: 5,
//     icon: customerServiceAgentFlatSvg,
//     title: "Customer Service Excellence",
//     content:
//       "Experience customer service excellence with our dedicated team. We're committed to providing the best support and satisfaction to our customers.",
//   },
// ];


export const services__Data = [
  {
    id: 1,
    icon: bugFixingMonochromaticSvg,
    title: "App Development",
    content:
      "Turn your ideas into innovative and user-friendly mobile applications with our expert app development services. We focus on creating seamless and feature-rich apps.",
  },
  {
    id: 2,
    icon: cloudComputingFlatSvg,
    title: "Custom Software Development",
    content:
      "Elevate your business with tailored software solutions. Our custom software development ensures your unique needs are met with cutting-edge technology.",
  },
  {
    id: 3,
    icon: dataCenterMonochromaticSvg,
    title: "Data Center Management",
    content:
      "Discover our data center management services, ensuring the security, efficiency, and performance of your data infrastructure.",
  },
  {
    id: 4,
    icon: customerServiceAgentFlatSvg,
    title: "Customer Service Excellence",
    content:
      "Experience customer service excellence with our dedicated team. We're committed to providing the best support and satisfaction to our customers.",
  },
  {
    id: 5,
    icon: callCenterFlat51145Svg,
    title: "Technical Support Services",
    content:
      "We offer comprehensive technical support services, including troubleshooting, software installation, system updates, and network setup. Our experts are here to assist you.",
  },
];


export const project__Data = [
    {
      name: "E-commerce Website",
      description: "Develop a fully functional e-commerce website.",
      image: administratorFlatSvg,
  

    },
    {
      name: "Mobile App Development",
      description: "Create a mobile app for iOS and Android.",
      image: administratorFlatSvg,
    },
    {
      name: "Database Optimization",
      description: "Optimize the database for better performance.",
      image: administratorFlatSvg,
    },
    {
      name: "Cybersecurity Audit",
      description: "Conduct a security audit of the IT infrastructure.",
      image: administratorFlatSvg,
    },
  ];

  export const home_data = [
    {
      id: "home",
      home__title: "Exploring Boundless Digital Horizons",
      home__subtitle:
        "where we turn ideas into reality. Explore our web and software solutions, unlocking limitless possibilities.",
      home__button: "See More",
      home__img: dataArrangingIsometricPng,
    },
  ];
  
  export const about_data = [
    {
      id: "About Us",
      home__title: "Our Journey and Commitment",
      home__subtitle:
        "Discover the story behind Pervasive Services, our dedicated team, and our unwavering commitment to delivering excellence in every service.",
      home__button: "Explore History",
      home__img: us,
    },
  ];
  
  export const contact_data = [
    {
      id: "contact",
      home__title: "Let's Connect",
      home__subtitle:
        "Ready to get started or have a question? Reach out to us anytime via email or our 24/7 chat service. We're here to assist you promptly.",
      home__button: "Contact Us Now",
      home__img: chatFlatSvg,
    },
  ];
  


//   export const features = [
//     {
//       id: "feature-1",
//       icon: star,
//       title: "Need Help Now?",
//       content:
//         "Our team is ready to assist you with any issues you might be facing. We're here to help you at any time.",
//     },
//     {
//       id: "feature-2",
//       icon: shield,
//       title: "Technical Support Services",
//       content:
//         "We offer a wide range of technical support services. Whether it's troubleshooting, software installation, system updates, or network setup, we've got you covered.",
//     },
//     {
//       id: "feature-3",
//       icon: send,
//       title: "More Information",
//       content:
//         "Check out more information about our services and how we can assist you",
//     },
//   ];

//   export const feedback = [
//     {
//       id: "feedback-1",
//       content:
//         "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
//       name: "Herman Jensen",
//       title: "Founder & Leader",
//       img: people01,
//     },
//     {
//       id: "feedback-2",
//       content:
//         "Money makes your life easier. If you're lucky to have it, you're lucky.",
//       name: "Steve Mark",
//       title: "Founder & Leader",
//       img: people02,
//     },
//     {
//       id: "feedback-3",
//       content:
//         "It is usually people in the money business, finance, and international trade that are really rich.",
//       name: "Kenn Gallagher",
//       title: "Founder & Leader",
//       img: people03,
//     },
//   ];

//   export const stats = [
//     {
//       id: "stats-1",
//       title: "User Active",
//       value: "3800+",
//     },
//     {
//       id: "stats-2",
//       title: "Trusted by Company",
//       value: "230+",
//     },
//     {
//       id: "stats-3",
//       title: "Transaction",
//       value: "$230M+",
//     },
//   ];


export const socialMediaLinks = [
  // { iconClass: "bx bxl-facebook", url: "https://facebook.com" },
  { iconClass: "bx bxl-instagram", url: "https://www.instagram.com/pervasive_services" },
  { iconClass: "bx bxl-twitter", url: "https://twitter.com/ps_webapps" },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.pervasive-services.com/",
      },
      {
        name: "How it Works",
        link: "https://www.pervasive-services.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.pervasive-services.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.pervasive-services.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.pervasive-services.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.pervasive-services.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.pervasive-services.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.pervasive-services.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.pervasive-services.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.pervasive-services.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.pervasive-services.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.pervasive-services.com/become-a-partner/",
      },
    ],
  },
];

//   export const socialMedia = [
//     {
//       id: "social-media-1",
//       icon: instagram,
//       link: "https://www.instagram.com/",
//     },
//     {
//       id: "social-media-2",
//       icon: facebook,
//       link: "https://www.facebook.com/",
//     },
//     {
//       id: "social-media-3",
//       icon: twitter,
//       link: "https://www.twitter.com/",
//     },
//     {
//       id: "social-media-4",
//       icon: linkedin,
//       link: "https://www.linkedin.com/",
//     },
//   ];

//   export const clients = [
//     {
//       id: "client-1",
//       logo: airbnb,
//     },
//     {
//       id: "client-2",
//       logo: binance,
//     },
//     {
//       id: "client-3",
//       logo: coinbase,
//     },
//     {
//       id: "client-4",
//       logo: dropbox,
//     },
//   ];
