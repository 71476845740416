// Layout.js
import React from "react";
// import Header from "./Header"; // Replace with your actual Header component
// import Footer from "./Footer"; // Replace with your actual Footer component
import Header from "../componets/Header";
import Footer from "../componets/Footer";


const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
