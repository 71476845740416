import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // const toggleTheme = () => {
  //   setIsDarkMode(!isDarkMode);
  //   // console.log("Clicked !!")
  //   document.body.classList.toggle('dark-theme', isDarkMode);
  // };
  const toggleTheme = () => {
    setIsDarkMode((prevIsDarkMode) => {
      // Use the previous value to toggle the theme
      const newIsDarkMode = !prevIsDarkMode;
      document.body.classList.toggle('dark-theme', newIsDarkMode);
      return newIsDarkMode;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
