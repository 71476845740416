import React from "react";
import Button from "../Button"; // Import your Button component
import "./ServiceCard.css";


const ServiceCard = ({ title, image, shortDescription, linkTo }) => {

    const handleExploreClick = () => {
        // Use history.push to navigate to the specified route
       
        // console.log(linkTo)
      };
    
  return (
    <div className="service-card">
      <img src={image} alt={title} className="service-image" />
      <div className="service-content">
        <h3 className="title">{title}</h3>
        <div className="card-container">
          <p className="description">{shortDescription}</p>
        </div>
        <Button linkTo={linkTo} text="Explore" onClick={handleExploreClick} />
        {/* <Link to={exploreLink}>
          <Button text="Explore" />
        </Link> */}
      </div>
    </div>
  );
};

export default ServiceCard;
