import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [wordCount, setWordCount] = useState(0);

  const handleTextareaChange = (e) => {
    const text = e.target.value;
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);

    if (words.length <= 100) {
      setMessage(text);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Basic name validation regex (no numbers allowed)
    const nameRegex = /^[^0-9]+$/;

    try {
      // Check if name is valid (does not contain numbers)
      if (!nameRegex.test(name)) {
        toast.error('Name should not contain numbers.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      // Check if email is valid
      if (!emailRegex.test(email)) {
        toast.error('Invalid email address.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      // Check if message exceeds the limit
      if (wordCount > 100) {
        toast.error('Message should be limited to 100 words.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      // Create a JSON object with the form data
      const formData = {
        name,
        email,
        message,
        timestamp: new Date().toString(),
      };

      // Convert the JSON object to a string
      const jsonData = JSON.stringify(formData);

      // Make a POST request to the Firebase Realtime Database REST API endpoint
      const response = await fetch('https://pervasive-services-default-rtdb.asia-southeast1.firebasedatabase.app/ContactRequests.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });

      // Check if the request was successful
      if (response.ok) {
        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
        setWordCount(0);

        toast.success('Message sent successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // Handle the case where the request was not successful
        toast.error('Error in sending message. Please try again later.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Error submitting form. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <br />
        <label>
          Message:
          <textarea
            value={message}
            onChange={handleTextareaChange}
            placeholder="Type your message here..."
          />
          <div className="word-limit">{wordCount}/100 words</div>
        </label>
        <br />
        <button type="submit">Send Message</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
