// import logo from "./pervasive_services-logo.png";
// import "./App.css";
// // import { ThemeProvider } from "./ThemeContext";

// import NotFound from "./Not_Found";
// import PrivacyPolicy from "./pages/PrivacyPolicy"; // Import the PrivacyPolicy component
// import TermsAndConditions from "./pages/TermsAndConditions";

// import React from "react";
// // import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {
//   BrowserRouter,
//   Routes, // instead of "Switch"
//   Route,
// } from "react-router-dom";

// // ... rest of your code

// import { ThemeProvider } from "./ThemeContext";
// // import { home_data, about_data, contact_data } from "./data/content";
// import Page from "./pages";
// import ServicesGrid from "./pages/SevicesGrid";
// import About from "./pages/About";
// import Contact from "./pages/Contact";
// // index.js or App.js

// function App() {
//   return (
//     <ThemeProvider>
//       <div className="App">
//         <BrowserRouter>
//           <Routes>
//             <Route path="/" element={<Page />} />
//             <Route path="/services" element={<ServicesGrid />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add this route */}
//             <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> {/* Add this route */}
//             <Route path="*" element={<NotFound />} />
//           </Routes>
//         </BrowserRouter>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default App;

// App.js
// import logo from "./pervasive_services-logo.png";
import "./App.css";
import NotFound from "./Not_Found";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from "./ThemeContext";
import Page from "./pages";
import ServicesGrid from "./pages/SevicesGrid";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout"; // Import the Layout component
import { contact_data } from "./data/content";
import AboutMore from "./pages/AboutMore";
import ServiceDetail from "./pages/ServiceDetail";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top when the route changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <BrowserRouter>
        <ScrollToTop />
        {/* <ScrollToTopButton /> */}
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Page />
                  
                </Layout>
              }
            />
            <Route
              path="/home"
              element={
                <Layout>
                  <Page />
                 
                </Layout>
              }
            />
            <Route
              path="/services"
              element={
                <Layout>
                  <ServicesGrid />
                  
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <About
               
                  />
                  
                </Layout>
              }
            />
            <Route
              path="/about-more"
              element={
                <Layout>
                  <AboutMore />
                  
                </Layout>
              }
            />
            <Route
              path="/services/:service__id"
              element={
                <Layout>
                  <ServiceDetail />
                  
                </Layout>
              }
            />

            {/* <Route path="/about" element={<About />} />
            <Route path="/about-more" element={<AboutMore />} /> */}

            <Route
              path="/contact"
              element={
                <Layout>
                  <Contact
                    contact__id={contact_data[0].id}
                    contact__title={contact_data[0].home__title}
                    contact__subtitle={contact_data[0].home__subtitle}
                    contact__button={contact_data[0].home__button}
                    contact__img={contact_data[0].home__img}
                  />
                 
                </Layout>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
