// ImageSlider.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css"; // Import the CSS file for animations
import { worldWideWebFlatlinesvg, dataArrangingIsometricSvg, teamBuildingPng } from '../../assets/img';

const images = [
  worldWideWebFlatlinesvg,
  dataArrangingIsometricSvg,
  teamBuildingPng,
  // Add more image paths as needed
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ImageSlider = () => {
  return (
    <Slider {...settings} className="image-slider">
      {images.map((image, index) => (
        <div key={index} className="slider-item">
          <img src={image} alt={`slide-${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
