import Header from "../componets/Header";
import Home from "./Home";
import About from "./About";

// import Projects from "./Projects";
import Contact from "./Contact";
// import Footer from "../componets/Footer";

import { home_data, contact_data } from "../data/content";
import ServicesGrid from "./SevicesGrid";

function Page() {
  return (
    <>
      <Header />

      {/* <!--========== SCROLL TOP ==========--> */}
      {/* <a href="#" className="scrolltop" id="scroll-top">
        <i className="bx bx-chevron-up scrolltop__icon"></i>
      </a> */}
      <Home
        home__id={home_data[0].id}
        home__title={home_data[0].home__title}
        home__subtitle={home_data[0].home__subtitle}
        home__button={home_data[0].home__button}
        home__img={home_data[0].home__img}
      />
      <ServicesGrid />
      {/* <Services /> */}
      <About
        // about__id={about_data[0].id}
        // about__title={about_data[0].home__title}
        // about__subtitle={about_data[0].home__subtitle}
        // about__button={about_data[0].home__button}
        // about__img={about_data[0].home__img}
      />
      {/* <Projects /> */}
      <Contact
        contact__id={contact_data[0].id}
        contact__title={contact_data[0].home__title}
        contact__subtitle={contact_data[0].home__subtitle}
        contact__button={contact_data[0].home__button}
        contact__img={contact_data[0].home__img}
      />
      {/* <Footer /> */}
    </>
  );
}

export default Page;
