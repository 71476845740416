import {
    customerServiceAgentFlatPng,
    webDevelopmentTwoColopng,
    teamMeetingPng,
    developmentPng,
    cloudComputingFlatPng,
    QA,
    us,
  } from "../assets/img";

  // data.js

export const aboutData = {
  about__id: "About Us",
  about__title: "Our Journey and Commitment",
  about__subtitle:
    "Discover the story behind Pervasive Services, our dedicated team, and our unwavering commitment to delivering excellence in every service.",
  about__button: "See More",
  about__img: us, // You may need to replace this with the actual image path
  aboutUsContent: {
    title: "Welcome to Pervasive Services",
    subtitle:
      "At Pervasive Services, we're not just a company; we're a story of passion, dedication, and digital innovation. Founded with a vision to transform ideas into reality, we embark on a journey to redefine the landscape of web app and software development.",
    vision: {
      title: "Our Vision",
      content: [
        "Unlocking Digital Possibilities",
        "We believe in unlocking the boundless potential of the digital realm. Our mission is to empower businesses and individuals by providing world-class software solutions that transcend expectations.",
      ],
    },
    soloProfessional: {
      title: "The Solo Professional",
      content: [
        "Pervasive Services is a startup powered by a singular force – a seasoned professional with a wealth of industrial experience. As the sole architect behind the scenes, our founder brings a unique perspective and a commitment to excellence in every project.",
      ],
    },
    craftingExcellence: {
      title: "Crafting Excellence",
      content: [
        "From Vision to Reality",
        "Our commitment extends beyond lines of code; it's a promise to craft excellence from the inception of an idea to the final product. Pervasive Services is where innovation meets precision, and every project is a testament to our unwavering dedication.",
      ],
    },
    exploreHistory: {
      title: "Explore Our History",
      content: [
        "A Story Unfolding",
        "Our journey is more than a timeline; it's a narrative painted with moments of innovation, challenges conquered, and milestones achieved. Explore the chapters of our history as we continue to push boundaries and shape the future of software development.",
      ],
    },
    pervasiveExperience: {
      title: "Join the Pervasive Experience",
      content: [
        "Beyond the Conventional",
        "Pervasive Services invites you to be part of a unique experience where conventional norms are surpassed, and digital horizons are explored. This is not just about us; it's an invitation to join a community that values creativity, expertise, and the relentless pursuit of excellence.",
      ],
    },
  },
};

export const services__Data = [
    {
      "id": 1,
      "icon": teamMeetingPng,
      "title": "Software Development Services",
      "content": {
        "shortDescription": "Comprehensive software development services covering Custom Software, Web Applications, and Mobile Apps to meet your diverse business needs.",
        "longDescription": "Pervasive Services offers a unified approach to Software Development Services, encompassing Custom Software Development, Web Application Development, and Mobile Application Development. Our expert team collaborates closely with clients to understand their unique business requirements, ensuring the delivery of innovative and high-performance solutions. Whether you need a tailor-made software solution, a robust web application, or a high-engaging mobile app, we are committed to bringing your vision to life. From concept to deployment, we prioritize user experience, performance, and functionality, ensuring the seamless integration of cutting-edge technologies into your digital landscape."
      }
    },
    {
      "id": 2,
      "icon": webDevelopmentTwoColopng,
      "title": "Software as a Service (SAAS) Solutions",
      "content": {
        "shortDescription": "Business-oriented SAAS solutions to streamline processes and enhance productivity.",
        "longDescription": "Pervasive Services takes pride in offering Software as a Service (SAAS) solutions designed with a business mindset. Our pre-developed SAAS solutions are crafted to address specific industry needs, providing scalable and efficient tools to streamline processes and enhance productivity. Whether you're looking for ready-to-use SAAS solutions or custom SAAS development tailored to your business requirements, we deliver innovative and user-friendly solutions that empower your organization in the digital age."
      }
    },
    {
      "id": 3,
      "icon": cloudComputingFlatPng,
      "title": "Cloud Application Development",
      "content": {
        "shortDescription": "Harness the power of the cloud with scalable and cost-effective application development.",
        "longDescription": "Pervasive Services specializes in Cloud Application Development, helping businesses leverage the scalability, flexibility, and cost-effectiveness of cloud platforms. Our cloud solutions enable seamless integration, efficient data storage, and enhanced accessibility. Whether you're looking to migrate existing applications to the cloud or develop new cloud-native solutions, our team has the expertise to optimize your operations and drive innovation."
      }
    },
    {
      "id": 4,
      "icon": developmentPng,
      "title": "Software Integration",
      "content": {
        "shortDescription": "Seamlessly connect and enhance your software ecosystem for improved efficiency.",
        "longDescription": "Pervasive Services excels in Software Integration, offering solutions to seamlessly connect and enhance your software ecosystem. Whether you need to integrate CRM and ERP systems, connect third-party APIs, or streamline communication between different software applications, our integration solutions ensure smooth data flow and real-time collaboration. Our expert team assesses your existing infrastructure, identifies integration opportunities, and implements robust solutions to transform your software landscape into a unified and synchronized environment."
      }
    },
    {
      "id": 5,
      "icon": QA,
      "title": "Software Testing and Quality Assurance",
      "content": {
        "shortDescription": "Comprehensive testing to ensure your software meets the highest standards of performance and security.",
        "longDescription": "Quality is at the core of Pervasive Services' Software Testing and Quality Assurance. Our comprehensive testing approach covers functional, performance, security, and usability testing to identify and address potential issues before they impact your users. From test planning to execution, our QA experts collaborate with your development team to deliver flawless software solutions. Whether you're launching a new application or updating an existing one, our commitment to quality assurance ensures a smooth and reliable user experience."
      }
    },
    {
      "id": 6,
      "icon": customerServiceAgentFlatPng,
      "title": "IT Support Services",
      "content": {
        "shortDescription": "Comprehensive IT Support services for seamless server management, proactive monitoring, bug fixes, and efficient error handling and reporting.",
        "longDescription": "Pervasive Services goes beyond development to provide comprehensive IT Support services, ensuring the seamless operation of your systems. Our experienced team excels in server handling, offering proactive monitoring to detect and address issues before they impact your operations. In addition to bug fixes, we specialize in efficient error handling and reporting, minimizing downtime and enhancing the overall reliability of your IT infrastructure. Whether it's routine server maintenance or addressing critical issues, our IT Support services are designed to keep your systems running smoothly, allowing you to focus on your core business activities."
      }
    }
  ];
  