import React from 'react';
import { Link } from 'react-router-dom';
import { aboutData } from '../data/data'; // Update the path accordingly
import { useNavigate } from 'react-router-dom';
// import Button from '../componets/Button';


function About() {
    const navigate = useNavigate();

  const handleClick = () => {
    // Use navigate to go to the desired route
    navigate('/about-more');
  };

    const {
        about__id,
        about__title,
        about__subtitle,
        about__button,
        about__img,
      } = aboutData;
    return ( 
        <section className="about section bd-container" id={about__id}>
        <div className="about__container bd-grid">
          <div className="about__data">
            <span className="section-subtitle about__initial">{about__id}</span>
            <h2 className="section-title about__initial">{about__title}</h2>
            <p className="about__description">{about__subtitle}</p>
            {/* Link to AboutMore when the button is clicked */}
            {/* {about__button}
            <Link >
             
            </Link> */}
            <a href="" >
            
            </a>
            {/* <Button */}
            <button onClick={handleClick}>{about__button}</button>

          </div>
  
          <img src={about__img} alt={about__id} className="about__img" />
        </div>
      </section>

     );
}

export default About;
