import React from "react";
// import Footer from "../components/Footer";
import Footer from "../componets/Footer";
// import Header from "../components/Header";
import Header from "../componets/Header";
const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className="PrivacyPolicy-container">
        <h1>Privacy Policy</h1>
        <div className="PrivacyPolicy-content">
          <h2>1. Data Collection</h2>
          <p>
            We collect and process data that you provide while using our app
            development and support services.
          </p>

          <h2>2. User Information</h2>
          <p>
            We may gather user data related to app interactions, usage, and
            support requests for improving our services.
          </p>

          <h2>3. Data Usage</h2>
          <p>
            Your data is used to enhance your app development experience,
            troubleshoot issues, and provide dedicated support.
          </p>

          <h2>4. Data Protection</h2>
          <p>
            We implement strict security measures to protect your data and
            maintain its confidentiality.
          </p>

          <h2>5. Data Sharing</h2>
          <p>
            We may share your data with trusted partners and service providers
            solely for app development and support purposes.
          </p>

          <h2>6. Cookie Usage</h2>
          <p>
            We utilize cookies to personalize your experience and analyze user
            behavior within our app and support channels.
          </p>

          <h2>7. Data Retention</h2>
          <p>
            We retain your data for the duration needed to deliver app
            development and support services and fulfill legal obligations.
          </p>

          <h2>8. User Rights</h2>
          <p>
            You have the right to access, rectify, and delete your personal
            data. Contact us for assistance.
          </p>

          <h2>9. Policy Updates</h2>
          <p>
            We may revise this policy to reflect changes in our app development
            and support services. Please review it periodically.
          </p>

          <h2>10. Contact Details</h2>
          <p>
            For inquiries or concerns regarding our Privacy Policy, please reach
            out to us at [Your Contact Information].
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
