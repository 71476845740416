import React from "react";
import "./assets/img/404-page-not-found-monochromatic.png"; // Import your 404 image


function NotFound() {

    // const [showAlert, setShowAlert] = useState(false);
    // const [alertMessage, setAlertMessage] = useState('');
  
    // const handleShowAlert = (message) => {
    //   setAlertMessage(message);
    //   setShowAlert(true);
  
    //   // Automatically hide the alert after a few seconds
    //   setTimeout(() => {
    //     setShowAlert(false);
    //   }, 5000); // Adjust the time as needed
    // };
  // src="./static/media/404-page-not-found-monochromatic.0c52a114208157baa9a097a34935bea4.svg" 
      
  return (
    <>
      <center>
        <div>
          <div className="custom-alert alert-warning" role="alert">
            This is a 404 error page.
          </div>

          <img
          className="not__found_img"
            src={"./assets/img/404-page-not-found-monochromatic.png"}
            alt="Page Not Found"

            height={400}
            width={400}
          />
          <h1>404 - Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <p>Or the page is under construction.</p>
          

          {/* You can style the alert and image as needed. */}
        </div>
      </center>
    </>
  );
}

export default NotFound;
