// // Button.js

// import React from 'react';
// import './Button.css'
// const Button = ({ linkTo, text }) => {
//   return (
//     <a href={linkTo} className="button" target="_blank" rel="noopener noreferrer">
//       {text}
//     </a>
//   );
// };

// export default Button;


import React from 'react';
import './Button.css';
import { useNavigate } from 'react-router-dom';

const Button= ({text,linkTo }) => {
    const navigate = useNavigate();

    const handleClick = (event) => {
    event.preventDefault();
      // Use navigate to go to the desired route
      navigate(linkTo);
    };
  
    
  return (
    <button className="button" onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;

