import React from "react";
import { services__Data } from "../data/data";
// import ServiceCard from "../components/Card/ServiceCard";
import ServiceCard from "../componets/Card/ServiceCard";
// src\componets\Card\ServiceCard.js

function ServicesGrid() {
  return (
    <section className="services section bd-container" id="services">
    <h2 className="section-title">Our amazing services</h2>
    <div className="service-grid">
      {services__Data.map((service) => (
        <ServiceCard
          key={service.id}
          title={service.title}
          image={service.icon}
          shortDescription={service.content.shortDescription}
          linkTo={`/services/${service.id}`}  // Replace with the actual link
        />
      ))}
    </div>
  </section>
  );
}

export default ServicesGrid;
