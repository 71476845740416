// ServiceDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import { services__Data } from '../data/data'; // Make sure to import your data
import './ServiceDetail.css'

const ServiceDetail = () => {
  const { service__id } = useParams();
  // console.log(service__id);


  // console.log('service__id:', service__id);

  // // Log the entire services__Data array
  // console.log('services__Data:', );

//   const service = services__Data.find(service => service.id === service__id.toString());
  const service = services__Data.find(service => service.id === Number(service__id));

  // console.log(JSON.stringify(service));

  if (!service) {
    // Handle case where service is not found
    return <div>Service not found</div>;
  }

  return (
    <section className="service-detail bd-container">
  <div className="service-detail__header">
    <h2>{service.title}</h2>
  </div>

  <div className="service-detail__content">
    <div className="service-detail__image">
      <img src={service.icon} alt={service.title} />
    </div>

    <div className="service-detail__info">
      <p>{service.content.shortDescription}</p>
    </div>
  </div>

  <div className="service-detail__description">
    <p>{service.content.longDescription}</p>
  </div>
</section>

  );
};

export default ServiceDetail;
