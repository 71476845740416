// Contact.js

import React from 'react';
import ContactForm from '../forms/ContactForm';
import './Contact.css';
import chatFlatPng from '../assets/img/chat-flat.png'; // Replace with the path to your image

function Contact(param) {
  return (
    <section className="contact section bd-container" id="contact">
      <div className="contact__container bd-grid">
        <div className="contact__header">
          <div className="contact__image">
            <img src={chatFlatPng} alt="Contact Us" style={{ width: '200px' }} />
          </div>
          <div className="contact__header-content">
            <span className="section-subtitle contact__initial">{param.contact__title}</span>
            <h2 className="section-title contact__initial">Contact us</h2>
            <p className="contact__description">{param.contact__subtitle}</p>
          </div>
        </div>
        <div className="contact__form">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default Contact;
