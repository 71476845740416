// import {Hero} from "../assets/img"
// function Home(param) {
//   // console.log(param);
//   return (



//     <section className="home" id="home">
//       <div className="home__container bd-container bd-grid">
//         <div className="home__data" id={param.home__id}>
//           <h1 className="home__title">{param.home__title}</h1>
//           <h2 className="home__subtitle">
//             {param.home__subtitle}
//           </h2>
//           <a href="#" className="home__button button">
//           {param.home__button}
//           </a>
//         </div>

       
//          <img src={param.home__img} alt={param.home__img}  className="home__img"  />
//       </div>
//     </section>
//   );
// }

// export default Home;


import React from 'react';
import ImageSlider from '../componets/Slider'; // Update the path based on your project structure

function Home(param) {
  return (
    // <!--========== HOME ==========-->
    <section className="home" id="home">
      <div className="home__container bd-container bd-grid">
        <div className="home__data" id={param.home__id}>
          <h1 className="home__title">
            <span className='dynamic-text'>
             {param.home__title} 
             </span>
            </h1>
          <h2 className="home__subtitle">
              <h3>Welcome to <span className='animate'>Pervasive Services</span>,
              </h3> 
            {param.home__subtitle}</h2>
          <a href="#" className="home__button button">
            {param.home__button}
          </a>
        </div>

        {/* Replace the single image with the ImageSlider component */}
        <ImageSlider />
      </div>
    </section>
  );
}

export default Home;

